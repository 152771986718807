.ModalWarning {
  .modal-content {
    border-radius: 6px;
    width: 500px;
  }
  .modal-header {
    border-bottom: 0;
    padding: 20px 20px 20px 16px;
    color: #333 !important;

    .modal-title {
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 24px !important;
    }
  }
  .modal-body {
    padding: 0px 20px;
    padding-bottom: 16px;
    font-size: 14px;
    color: #000000d9 !important;
    font-weight: 400;
  }
  .modal-footer {
    border-top: 0;
    padding: 20px;
    padding-top: 10px;
  }
}
