$bs-primary: #5180fb;
$bs-light-primary: #e7f3ff;
$bs-base-text-color: #3f4254;
$bs-active-text-color: #e7f3ff;
$bs-border-color: #e4e6ef;

.btn-not-active {
  border-color: $bs-border-color !important;
  color: $bs-base-text-color !important;
  background-color: transparent !important;
  &:hover {
    border-color: $bs-primary !important;
  }
}
.btn-is-active {
  color: $bs-primary !important;
  border-color: $bs-primary !important;
  background-color: $bs-light-primary !important;
  &:hover {
    background-color: $bs-primary !important;
    border-color: transparent !important;
    color: $bs-active-text-color !important;
  }
}
