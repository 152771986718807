.guest-base-footer{
    font-size: 14px;
}

/* @media screen and (max-width: 769px) {
    .guest-base-footer {
        font-size: 13px !important;
    }
} */
@media screen and (max-width: 400px) {
    .guest-base-footer {
        font-size: 11px !important;
    }
}