.Message100 {
  .i-send,
  .i-receivce {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding: 1px 3px;
    color: #fff;
  }

  .i-send {
    background-color: #1877f2;
  }

  .i-receivce {
    background-color: #00b416;
  }
  .text-time {
    font-weight: 600;
    font-size: '14px';
    line-height: '24px';
  }
}
