.ChooseTemplateInvoice {
  .btn-back {
    width: 42px !important;
    min-width: 42px;
    border: 1px solid #ced4da;
    background-color: #fff;
    &:hover,
    &:focus {
      border-color: blue;
      background-color: #fff !important;
    }
    i {
      color: hsl(1, 0%, 30%);
    }
  }
  .dropdown.se-dd {
    .dropdown-toggle {
      width: 100%;
      color: #495057 !important;
      text-align: start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      background-color: #fff;
      background-clip: padding-box !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:not(.dd-color-picker)::after {
        display: inline-block !important;
        color: #495057 !important;
        content: '';
        border-top: 0.5rem solid;
        border-right: 0.5rem solid transparent;
        border-bottom: 0;
        border-left: 0.5rem solid transparent;
      }

      &:hover,
      &:focus {
        background-color: #fff !important;
      }
    }

    .dropdown-menu {
      min-width: 5rem;
    }
  }

  .item-template {
    width: 410px;
    max-height: 710px;

    img.thumbnail {
      &:hover {
        border: 2px solid #428bca;
        cursor: pointer;
      }
    }

    .template-symbol {
      font-size: 15px;
      font-weight: 600;
      text-align: center;
    }
  }
}
