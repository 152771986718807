$primary-color: #06beb6;
$secondary-color: #48b1bf;
$text-color: white;

.cardCertBg {
  background: $primary-color; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    $primary-color,
    $secondary-color
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    $primary-color,
    $secondary-color
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;

  & .iconAction {
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }

  & .table thead th,
  .table tbody td {
    color: inherit;
    background-color: transparent !important;
    padding: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  & .table thead th {
    font-size: 15px;
    font-weight: bold !important;
  }

  & .table-responsive {
    max-height: 150px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 3px;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.dashboard-footer {
  position: fixed;
  width: 100%;
  height: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.2);
}
